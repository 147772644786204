import { createAction, createAsyncAction } from '../_helpers/utils'
import { contentTypes, exhibitionTypes, eventTypes, survivorTypes, formTypes } from '../_constants/'
import {
    Boilerplate,
    Career,
    CuratorsNotebook,
    Events,
    Exhibitions,
    Form,
    MailChimp,
    MenuLinks,
    Pages,
    Search,
    Survivor,
    Donation,
    SpecialEvent,
    Hours,
    AlertModal,
} from '../_services'


export const loadPage = createAsyncAction(
    contentTypes.PAGE_DATA_REQUEST,
    contentTypes.PAGE_DATA_SUCCESS,
    Pages.get,
    contentTypes.PAGE_DATA_FAILURE,
)

export const loadGlobalHours = createAsyncAction(
    contentTypes.GLOBAL_HOURS_REQUEST,
    contentTypes.GLOBAL_HOURS_SUCCESS,
    Hours.get
)

export const loadMenuLinks = createAsyncAction(
    contentTypes.MENU_LINKS_REQUEST,
    contentTypes.MENU_LINKS_SUCCESS,
    MenuLinks.getMenu
)

export const loadHeaderLinks = createAsyncAction(
    contentTypes.HEADER_LINKS_REQUEST,
    contentTypes.HEADER_LINKS_SUCCESS,
    MenuLinks.getHeader
)

export const loadFooterLinks = createAsyncAction(
    contentTypes.FOOTER_LINKS_REQUEST,
    contentTypes.FOOTER_LINKS_SUCCESS,
    MenuLinks.getFooterLinks
)

export const loadFooterContent = createAsyncAction(
    contentTypes.FOOTER_CONTENT_REQUEST,
    contentTypes.FOOTER_CONTENT_SUCCESS,
    MenuLinks.getFooterContent
)

export const loadExhibitionList = createAsyncAction(
    exhibitionTypes.EXHIBITION_LIST_REQUEST,
    exhibitionTypes.EXHIBITION_LIST_SUCCESS,
    Exhibitions.getList
)

export const loadExhibitionPage = createAsyncAction(
    exhibitionTypes.EXHIBITION_PAGE_REQUEST,
    exhibitionTypes.EXHIBITION_PAGE_SUCCESS,
    Exhibitions.get
)

export const loadEventList = createAsyncAction(
    eventTypes.EVENT_LIST_REQUEST,
    eventTypes.EVENT_LIST_SUCCESS,
    Events.getList
)

export const loadEducationEventList = createAsyncAction(
    eventTypes.EVENT_LIST_REQUEST,
    eventTypes.EVENT_LIST_SUCCESS,
    Events.getEducationList
)

export const loadEventPage = createAsyncAction(
    eventTypes.EVENT_PAGE_REQUEST,
    eventTypes.EVENT_PAGE_SUCCESS,
    Events.get
)

export const loadSurvivorList = createAsyncAction(
    survivorTypes.SURVIVOR_LIST_REQUEST,
    survivorTypes.SURVIVOR_LIST_SUCCESS,
    Survivor.getList
)

export const loadSurvivorPage = createAsyncAction(
    survivorTypes.SURVIVOR_PAGE_REQUEST,
    survivorTypes.SURVIVOR_PAGE_SUCCESS,
    Survivor.get
)

export const getCareers = createAsyncAction(
    contentTypes.GET_CAREERS_REQUEST,
    contentTypes.GET_CAREERS_SUCCESS,
    Career.getList
)

export const getJob = createAsyncAction(
    contentTypes.GET_JOB_REQUEST,
    contentTypes.GET_JOB_SUCCESS,
    Career.get
)

export const getBoilerplate = createAsyncAction(
    contentTypes.GET_BOILERPLATE_REQUEST,
    contentTypes.GET_BOILERPLATE_SUCCESS,
    Boilerplate.get
)

export const loadCuratorsNotebook = createAsyncAction(
    contentTypes.GET_CURATORS_NOTEBOOK_REQUEST,
    contentTypes.GET_CURATORS_NOTEBOOK_SUCCESS,
    CuratorsNotebook.getList
)

export const getCuratorsNotebookItem = createAsyncAction(
    contentTypes.GET_CURATORS_NOTEBOOK_ITEM_REQUEST,
    contentTypes.GET_CURATORS_NOTEBOOK_ITEM_SUCCESS,
    CuratorsNotebook.get
)

export const subscribe = createAsyncAction(
    contentTypes.SUBSCRIBE_REQUEST,
    contentTypes.SUBSCRIBE_SUCCESS,
    MailChimp.post,
    contentTypes.SUBSCRIBE_FAILURE
)

export const getGroupFormFields = createAsyncAction(
    formTypes.GET_GROUP_FORM_REQUEST,
    formTypes.GET_GROUP_FORM_SUCCESS,
    Form.Group.get,
)

export const postGroupForm = createAsyncAction(
    formTypes.POST_GROUP_FORM_REQUEST,
    formTypes.POST_GROUP_FORM_SUCCESS,
    Form.Group.post,
    formTypes.POST_GROUP_FORM_FAILURE,
)

export const getVolunteerFormFields = createAsyncAction(
    formTypes.GET_VOLUNTEER_FORM_REQUEST,
    formTypes.GET_VOLUNTEER_FORM_SUCCESS,
    Form.Volunteer.get,
)

export const postVolunteerForm = createAsyncAction(
    formTypes.POST_VOLUNTEER_FORM_REQUEST,
    formTypes.POST_VOLUNTEER_FORM_SUCCESS,
    Form.Volunteer.post,
    formTypes.POST_VOLUNTEER_FORM_FAILURE,
)

export const getJobApplicationFormFields = createAsyncAction(
    formTypes.GET_JOB_APPLICATION_FORM_REQUEST,
    formTypes.GET_JOB_APPLICATION_FORM_SUCCESS,
    Form.JobApplication.get,
)

export const getDonationsList = createAsyncAction(
    contentTypes.DONATION_REQUEST,
    contentTypes.DONATION_SUCCESS,
    Donation.getList
)

export const getSpecialEvent = createAsyncAction(
    contentTypes.SPECIAL_EVENT_REQUEST,
    contentTypes.SPECIAL_EVENT_SUCCESS,
    SpecialEvent.get,
)

export const getAlertModal = createAsyncAction(
    contentTypes.ALERT_MODAL_REQUEST,
    contentTypes.ALERT_MODAL_SUCCESS,
    AlertModal.get,
)

export const postJobApplicationForm = (data) => {
    return dispatch => {
        Form.JobApplication.post(data)
            .then((res) => dispatch(success(res)))
            .catch((error) => dispatch(fail(error)))
    }
    function success({ message }) { return { type: formTypes.POST_JOB_APPLICATION_FORM_SUCCESS, message } }
    function fail(error) { return { type: formTypes.POST_JOB_APPLICATION_FORM_FAILURE, error } }
}

export const search = (query) => {
    return dispatch => {
        Search.get(query).then(
            search => dispatch(success(search.data)),
        )
    }
    function success(search) { return { type: contentTypes.SEARCH_SUCCESS, search, query } }
}

export const toggleSurvivorFilters = createAction(survivorTypes.TOGGLE_FILTERS)

export const updatePosition = createAction(survivorTypes.UPDATE_POSITION)

export const changeSurvivorFilter = createAction(survivorTypes.CHANGE_FILTER)

export const clearSurvivor = createAction(survivorTypes.CLEAR_SURVIVOR)

export const clearSurvivorFilters = createAction(survivorTypes.CLEAR_SURVIVOR_FILTERS)

export const clearEvents = createAction(eventTypes.CLEAR_EVENT_LIST)

export const clearEvent = createAction(eventTypes.CLEAR_EVENT)

export const clearExhibition = createAction(exhibitionTypes.CLEAR_EXHIBITION)

export const disableSplash = createAction(contentTypes.DISABLE_SPLASH)

export const fadeSplash = createAction(contentTypes.FADE_SPLASH)

export const toggleMenu = createAction(contentTypes.TOGGLE_MENU)

export const toggleSearch = createAction(contentTypes.TOGGLE_SEARCH)

export const updateQuery = createAction(contentTypes.UPDATE_QUERY)

export const showModal = createAction(contentTypes.SHOW_MODAL)

export const hideModal = createAction(contentTypes.HIDE_MODAL)

export const resetErrorAndMessage = createAction(formTypes.RESET)

export const toggleHeaderAndFooter = createAction(contentTypes.TOGGLE_HEADER_AND_FOOTER)
