import React, { useEffect, lazy } from 'react'
import { Router, Route, Switch, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from './_helpers'
import {
    Footer,
    Header,
} from './Components'
import { devices } from './Components/Common'
import './css/normalize.css'
import 'react-dates/lib/css/_datepicker.css'
import './css/App.scss'

import styled from 'styled-components'
import ReactGA from 'react-ga'
import Modal from 'react-modal'
import { errorServices } from './_services'
import { getBoilerplate, getGroupFormFields, getVolunteerFormFields, getJobApplicationFormFields, getAlertModal } from './_actions'

const Careers = lazy(() => import(/* webpackChunkName: "Careers" */ './Components/Careers/Careers'))
const CuratorsItem = lazy(() => import(/* webpackChunkName: "CuratorsItem" */ './Components/Curator/CuratorsItem'))
const CuratorsList = lazy(() => import(/* webpackChunkName: "CuratorsList" */ './Components/Curator/CuratorsList'))
const Events = lazy(() => import(/* webpackChunkName: "Event" */ './Components/Events/Events'))
const Exhibitions = lazy(() => import(/* webpackChunkName: "Exhibitions" */ './Components/Exhibitions/Exhibitions'))
const ExhibitionView = lazy(() => import(/* webpackChunkName: "ExhibitionView" */ './Components/Exhibitions/ExhibitionView'))
const GroupTour = lazy(() => import(/* webpackChunkName: "GroupTour" */ './Components/Forms/GroupTour'))
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Components/Home/Home'))
const IEModal = lazy(() => import(/* webpackChunkName: "IEModal" */ './Components/Global/IEModal'))
const Job = lazy(() => import(/* webpackChunkName: "Job" */ './Components/Careers/Job'))
const JobApplication = lazy(() => import(/* webpackChunkName: "JobApplication" */ './Components/Forms/JobApplication'))
const PageView = lazy(() => import(/* webpackChunkName: "PageView" */ './Components/Content/PageView'))
const PastExhibitions = lazy(() => import(/* webpackChunkName: "PastExhibitions" */ './Components/Exhibitions/PastExhibitions'))
const Search = lazy(() => import(/* webpackChunkName: "Search" */ './Components/Global/Search'))
const Survivors = lazy(() => import(/* webpackChunkName: "Survivors" */ './Components/Survivors/Survivors'))
const Volunteer = lazy(() => import(/* webpackChunkName: "Volunteer" */ './Components/Forms/Volunteer'))
const AlertModal = lazy(() => import(/* webpackChunkName: "IEModal" */ './Components/Global/AlertModal'))

const spanish = document.documentElement.lang === 'es'
ReactGA.initialize(`UA-11180573-${spanish ? 2 : 1}`, { debug: false })
Modal.setAppElement('#root')


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === 'production') {
            errorServices.sendError(
                error.message,
                info.componentStack,
                window.location.href
            )
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) return <h1>Something went wrong.</h1>
        return this.props.children
    }
}

const PageRoute = ({ component: Component, path, exact, ...props }) =>
    <Route
        exact={exact}
        path={path}
        render={() => <Component {...props} key={props.location.key} match={props.match || props.computedMatch} />}
    />

const ScrollTopRoute = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            GAStarted: false
        }
    }

    componentDidMount() {
        const { boilerplate, dispatch, groupForm, volunteerForm, jobApplicationForm, alertModal } = this.props
        if (!Object.keys(boilerplate).length) dispatch(getBoilerplate())
        if (!groupForm.states.length && !groupForm.grades.length) dispatch(getGroupFormFields())
        if (!volunteerForm.languages.length) dispatch(getVolunteerFormFields())
        if (!jobApplicationForm.positions.length) dispatch(getJobApplicationFormFields())
        if (!Object.keys(alertModal).length) dispatch(getAlertModal())

        this.sendGA(window.location.pathname)
        history.listen((location) => {
            this.sendGA(location.pathname)
        })
    }

    sendGA = (pathname) => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(pathname)
        }
    }

    render() {
        const { ie, hideHeaderAndFooter, alertModal, showAlertModal } = this.props
        const spanish = document.documentElement.lang === 'es'
        const modalEnabled = alertModal.enabled && alertModal.pages.map(page => page.url).includes(window.location.pathname)
        const newVisitorsOnly = alertModal.show_to_new_visitors ? showAlertModal : true

        // If the current path leads to the video page, don't show the header and footer
        return (
            <ErrorBoundary>
                <Router history={history}>
                    <MainPage ie={ie ? 1 : 0}>
                        {!hideHeaderAndFooter && <Route path='*' component={Header} />}
                        <ScrollTopRoute />
                        <PageWrap hideHeaderAndFooter={hideHeaderAndFooter}>
                            <Switch>
                                <PageRoute exact path='/' component={Home} />

                                <PageRoute exact path={spanish ? '/eventos/' : '/events/'} component={Events} />
                                <PageRoute exact path={spanish ? '/eventos/pasadas/' : '/events/past/'} component={Events} past={true} />
                                <PageRoute exact path={spanish ? '/eventos/:slug/' : '/events/:slug/'} component={ExhibitionView} isEvent={true} />

                                <PageRoute exact path={spanish ? '/exposiciones/' : '/exhibitions/'} component={Exhibitions} />
                                <PageRoute exact path={spanish ? '/exposiciones/pasadas/' : '/exhibitions/past/'} component={PastExhibitions} />
                                <PageRoute exact path={spanish ? '/exposiciones/:slug/' : '/exhibitions/:slug/'} component={ExhibitionView} />

                                <PageRoute path={spanish ? '/supervivientes/' : '/survivors/'} component={Survivors} />

                                <PageRoute exact path={spanish ? '/visite/viaje-grupal/' : '/visit/group-tour/'} component={GroupTour} />
                                <PageRoute exact path={spanish ? '/voluntariado/applicar/' : '/volunteer/apply/'} component={Volunteer} />

                                <PageRoute exact path={spanish ? '/empleo/' : '/careers/'} component={Careers} />
                                <PageRoute exact path={spanish ? '/empleo/aplicar/:position?' : '/careers/apply/:position?'} component={JobApplication} />
                                <PageRoute exact path={spanish ? '/empleo/:slug' : '/careers/:slug'} component={Job} />

                                <PageRoute exact path={spanish ? '/colecciones/cuaderno-de-conservadores/' : '/collections/curators-notebook'} component={CuratorsList} />
                                <PageRoute exact path={spanish ? '/colecciones/cuaderno-de-conservadores/:slug' : '/collections/curators-notebook/:slug'} component={CuratorsItem} />

                                <PageRoute exact path={spanish ? '/buscar/:query' : '/search/:query'} component={Search} />

                                <PageRoute exact path='/:page/:category?/:subCategory?' component={PageView} />
                            </Switch>
                        </PageWrap>
                        {!hideHeaderAndFooter && <Route path='*' component={Footer} />}
                        {ie ? <IEModal /> : ''}
                        {(modalEnabled && newVisitorsOnly) && <AlertModal content={alertModal} />}
                    </MainPage>
                </Router>
            </ErrorBoundary>
        )
    }
}

function mapStateToProps(state) {
    return {
        ie: state.content.ie,
        page: state.content.page,
        boilerplate: state.content.boilerplate,
        groupForm: state.formField.groupForm,
        volunteerForm: state.formField.volunteerForm,
        jobApplicationForm: state.formField.jobApplicationForm,
        hideHeaderAndFooter: state.content.hideHeaderAndFooter,
        alertModal: state.content.alertModal,
        showAlertModal: state.content.showAlertModal,
    }
}

const PageWrap = styled.main`
    padding-top: ${({ hideHeaderAndFooter }) => hideHeaderAndFooter ? '0' : '80px'};
    flex: 1;
    @media ${devices.tablet} {
        padding-top: ${({ hideHeaderAndFooter }) => hideHeaderAndFooter ? '0' : '60px'};
    }
`

const MainPage = styled.div`
    display: ${props => props.ie ? 'block' : 'flex'};
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`

const connectedApp = connect(mapStateToProps)(App)
export { connectedApp as App }
