import React, { useState } from 'react'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel as CarouselComp } from 'react-responsive-carousel'
import {
    UpperHeader,
    devices,
    ArticleTextWrap,
} from '../../Common'
import { VideoModal } from '../../Content'

export const SimpleCarousel = ({ slides, smallTitle, content, survivor }) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [videoURL, setVideoURL] = useState('')

    const handleChange = (id) => setActiveSlide(id)

    const openModal = (videoURL) => {
        setShowModal(true)
        setVideoURL(videoURL)
    }

    const closeModal = () => setShowModal(false)

    const height = 440
    return (
        <div>
            { smallTitle ? <UpperHeader marginbottom='10'>{smallTitle}</UpperHeader> : ''}
            <StyledCarousel
                showStatus={false}
                showArrows={slides.length > 1}
                showThumbs={false}
                showIndicators={false}
                infiniteLoop={slides.length > 1}
                stopOnHover={false}
                interval={5000}
                transitionTime={1200}
                autoPlay={false}
                selectedItem={activeSlide}
                onChange={handleChange}
                survivor={survivor}
            >
                {slides.map((img, i) =>
                    <div key={img.image}>
                        {img.video_url ? (
                            <VideoThumbnail
                                image={img.video_thumbnail || img.youtube_thumbnail || img.vimeo_thumbnail}
                                height={height}
                                role='img'
                                aria-label={`${img.title ? `image-title-${i} – ` : ''}${img.credit}`}
                            >
                                <VideoPlayButton
                                    onClick={() => openModal(img.video_url)}
                                    role='button'
                                    aria-label={`Play ${img.title || img.subtitle || img.info} video`}
                                />
                            </VideoThumbnail>
                        ) : (
                            <SlideImageCard
                                height={height}
                                background={img.image}
                                orientation={img.orientation === 'portrait' ? 'contain' : 'cover'}
                                role='img'
                                aria-label={`${img.title ? `${img.title} – ` : ''}${img.credit}`}
                            />
                        )}
                        <Caption>
                            {img.title ? <ImageTitle>{img.title}</ImageTitle> : ''}
                            {img.credit ? <ImageCredit>{img.credit}</ImageCredit> : ''}
                            {img.image_caption ? <ImageCredit>{img.image_caption}</ImageCredit> : ''}
                        </Caption>
                    </div>
                )}
            </StyledCarousel>
            {survivor && slides.length > 1 ? (
                <CarouselCounter>{`${activeSlide + 1} of ${slides.length}`}</CarouselCounter>
            ) : ''}
            { content ? <ArticleTextWrap margintop='10' dangerouslySetInnerHTML={{ __html: content }} /> : ''}
            <VideoModal
                url={videoURL}
                showModal={showModal}
                closeModal={closeModal}
            />
        </div>
    )
}


const StyledCarousel = styled(CarouselComp)`
    height: ${props => props.height}px;
    max-width: 720px;
    ${props => props.survivor ? 'margin: auto;' : ''}

    .slide {
        height ${props => props.height}px;
        background-color: white !important;
        @media ${devices.mobile} {
            ${'' /* height: 210px; disabled to show captions */}
        }
    }

    && {
        .control-dots {
            display: none;
        }
    }

    @media ${devices.mobile} {
        ${'' /* height: 210px; disabled to show captions */}
    }
`

const VideoThumbnail = styled.div`
    height: ${props => props.height}px;
    width: 100%;
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
    cursor: pointer;

    @media ${devices.mobile} {
        height: 210px;
    }
`

const VideoPlayButton = styled.div`
    height: 100%;
    background-image: url(/static/images/Video_Play.png);
    background-position: center;
    background-size: 200px;
    background-repeat: no-repeat;
    cursor: pointer;

    @media ${devices.mobile} {
        height: 210px;
    }
`

const ImageCard = styled.div`
    width: 100%;
    max-width: 720px;
    height: ${({ height = 200 }) => height}px;
    background: center / ${props => props.orientation || 'cover'} no-repeat url(${props => props.background});
    background-size: contain;
`

const SlideImageCard = styled(ImageCard)`
    height: ${props => props.height}px;
    max-width: 720px;
    margin-bottom: 0;
    background-position: top;
    @media ${devices.mobile} {
        height: 210px;
    }
`

const CarouselCounter = styled.div`
    text-align: right;
    margin-right: 140px;
    margin-top: 5px;

    @media (max-width: 1450px) {
        margin-right: 90px;
    }

    @media (max-width: 925px) {
        margin-right: 50px;
    }

    @media (max-width: 825px) {
        margin-right: 0;
    }
`

const Caption = styled.div`
    text-align: left;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.45);
`

const ImageTitle = styled.div`
    font-weight: 500;
`

const ImageCredit = styled.div`
`
