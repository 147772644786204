import axios from 'axios'
import { cookies } from '../_helpers'

export const HeroCarousels = {
    getList: () => axios.get('/api/content/hero_carousels/')
}

export const Pages = {
    get: (id, menu, secondaryLookup) => {
        const url = window.location.pathname
        let query = `?url=${encodeURIComponent(`${url}${url[url.length - 1] !== '/' ? '/' : ''}`)}`
        if (menu) query += `&menu=${menu}`
        if (secondaryLookup) query += `&secondary=${secondaryLookup}`
        const route = `/api/content/page/${id}/${query}`
        return axios.get(route)
    }
}

export const Hours = {
    get: () => axios.get('/api/content/hours/'),
}

export const MenuLinks = {
    getMenu: () => axios.get('/api/content/menu-link/'),
    getHeader: () => axios.get('/api/content/header-link/'),
    getFooterLinks: () => axios.get('/api/content/footer-link/'),
    getFooterContent: () => axios.get('/api/content/footer-content/'),
}

export const Career = {
    getList: () => axios.get('/api/content/careers/'),
    get: (slug) => axios.get(`/api/content/careers/${slug}/`),
}

export const MailChimp = {
    post: (email) => axios.post('/mailchimp-subscribe/', { email }, { headers: { 'X-CSRFToken': cookies.getCookie('csrftoken') } }),
}

export const Search = {
    get: (query) => axios.get(`/api/search/?text=${query}`),
}

export const Boilerplate = {
    get: () => axios.get(`/api/translate/boilerplate/`),
}

export const CuratorsNotebook = {
    getList: () => axios.get('/api/curators/notebook/'),
    get: (slug) => axios.get(`/api/curators/notebook/${slug}/`)
}

export const Donation = {
    getList: () => axios.get('/api/content/donation/')
}

export const SpecialEvent = {
    get: (slug) => axios.get(`/api/content/special-event/${slug}/`)
}

export const AlertModal = {
    get: () => axios.get('/api/content/alertmodal/')
}
