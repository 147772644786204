import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
    MuseumModal,
    Modal,
    Title,
    HR,
    Info,
    Close,
    ModalBackground,
    Logo,
} from '../Common/Modal'
import { devices } from '../Common'
import { hideModal } from '../../_actions'

const AlertModal = ({ content }) => {
    const targetRef = useRef(null)
    let targetElement = null
    const { title, body, button_text, button_link } = content
    const dispatch = useDispatch()
    const [displayModal, setDisplayModal] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setDisplayModal(true)
        }, 500)
        targetElement = targetRef.current
        disableBodyScroll(targetElement)
        window.localStorage.setItem('displayedAlertModal', true)
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const closeModal = () => {
        enableBodyScroll(targetElement)
        setDisplayModal(false)
        dispatch(hideModal())
    }

    return (
        <MuseumModal show={displayModal}>
            <Modal show={displayModal} className='test'>
                <ModalContent show={displayModal}>
                    <Logo src='/static/images/hmh-logo.svg' />
                    <Title>{title}</Title>
                    <HR />
                    <Info dangerouslySetInnerHTML={{ __html: body }} />
                    {button_link ? <Button href={button_link} target="_blank" rel="noopener noreferrer">
                        {button_text}
                    </Button> : ''}
                </ModalContent>
                <Close title='Close (Esc)' className='mfp-close newsletter-close' onClick={closeModal} />
            </Modal>
            <ModalBackground show={displayModal} ref={targetElement} onClick={closeModal} />
        </MuseumModal>
    )
}

const ModalContent = styled.div`
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000000;
`

const Button = styled.a`
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid white;
    background: transparent;
    color: white;
    padding: 5px 40px;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 2px;
    font-family: 'Financier';

    @media ${devices.mobile} {
        // for some reason this is the same size as 17px in MembersInfo
        font-size: 14px;
        padding: 5px 25px;
    }
`

export default AlertModal