import { contentTypes } from '../_constants'
import { BrowserDetect } from '../_helpers/utils'

const initialState = {
    page: {},
    globalHours: {},
    menuLinks: [],
    headerLinks: [],
    footerLinks: [],
    footerContent: {},
    careers: [],
    job: {},
    // showSplash: !window.localStorage.getItem('displayedSplash') && !/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent),
    showSplash: false,
    // showNewMuseumModal: !window.localStorage.getItem('displayedSplash') ? false : !window.localStorage.getItem('displayedNewMuseumModal'),
    fadingSplash: false,
    ie: BrowserDetect() === 'Explorer',
    searchResults: [],
    menuOpen: false,
    searchOpen: false,
    boilerplate: {},
    curatorsNotebook: [],
    curatorsNotebookItem: {},
    donations: [],
    specialEvent: {},
    hideHeaderAndFooter: false,
    showAlertModal: !window.localStorage.getItem('displayedAlertModal') ? true : !window.localStorage.getItem('displayedAlertModal'),
    alertModal: {},
}

export function content(state = initialState, action) {
    switch (action.type) {
        case contentTypes.PAGE_DATA_SUCCESS:
            return {
                ...state,
                page: action.payload,
                notFound: false,
                searchResults: [],
            }
        case contentTypes.PAGE_DATA_FAILURE:
            return {
                ...state,
                notFound: true,
            }
        case contentTypes.GLOBAL_HOURS_SUCCESS:
            return {
                ...state,
                globalHours: action.payload,
            }
        case contentTypes.MENU_LINKS_SUCCESS:
            return {
                ...state,
                menuLinks: action.payload,
            }
        case contentTypes.HEADER_LINKS_SUCCESS:
            return {
                ...state,
                headerLinks: action.payload,
            }
        case contentTypes.FOOTER_LINKS_SUCCESS:
            return {
                ...state,
                footerLinks: action.payload,
            }
        case contentTypes.FOOTER_CONTENT_SUCCESS:
            return {
                ...state,
                footerContent: action.payload[0],
            }
        case contentTypes.GET_CAREERS_SUCCESS:
            return {
                ...state,
                careers: action.payload,
            }
        case contentTypes.GET_JOB_SUCCESS:
            return {
                ...state,
                job: action.payload,
            }
        case contentTypes.DISABLE_SPLASH:
            return {
                ...state,
                showSplash: false,
            }
        case contentTypes.FADE_SPLASH:
            return {
                ...state,
                fadingSplash: true,
            }
        case contentTypes.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                newsletterMessage: action.payload,
                newsletterError: '',
            }
        case contentTypes.SUBSCRIBE_FAILURE:
            return {
                ...state,
                newsletterMessage: '',
                newsletterError: action.payload,
            }
        case contentTypes.SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: action.search,
                query: action.query,
                searchOpen: !action.search.length,
                menuOpen: !action.search.length,
            }
        case contentTypes.TOGGLE_MENU:
            return {
                ...state,
                menuOpen: action.payload,
            }
        case contentTypes.TOGGLE_SEARCH:
            return {
                ...state,
                searchOpen: action.payload,
            }
        case contentTypes.UPDATE_QUERY:
            return {
                ...state,
                query: action.payload,
            }
        case contentTypes.GET_BOILERPLATE_SUCCESS:
            return {
                ...state,
                boilerplate: action.payload,
            }
        case contentTypes.SHOW_MODAL:
            return {
                ...state,
                showAlertModal: true,
            }
        case contentTypes.HIDE_MODAL:
            return {
                ...state,
                showAlertModal: false,
            }
        case contentTypes.GET_CURATORS_NOTEBOOK_SUCCESS:
            return {
                ...state,
                curatorsNotebook: action.payload,
            }
        case contentTypes.GET_CURATORS_NOTEBOOK_ITEM_SUCCESS:
            return {
                ...state,
                curatorsNotebookItem: action.payload,
            }
        case contentTypes.DONATION_SUCCESS:
            return {
                ...state,
                donations: action.payload,
            }
        case contentTypes.SPECIAL_EVENT_SUCCESS:
            return {
                ...state,
                specialEvent: action.payload,
            }
        case contentTypes.TOGGLE_HEADER_AND_FOOTER:
            return {
                ...state,
                hideHeaderAndFooter: action.payload,
            }
        case contentTypes.ALERT_MODAL_SUCCESS:
            return {
                ...state,
                alertModal: action.payload,
            }
        default:
            return state
    }
}
