import { createConstants } from '../_helpers/utils'

export const contentTypes = createConstants(
    'PAGE_DATA_REQUEST',
    'PAGE_DATA_SUCCESS',
    'PAGE_DATA_FAILURE',
    'GLOBAL_HOURS_REQUEST',
    'GLOBAL_HOURS_SUCCESS',
    'MENU_LINKS_REQUEST',
    'MENU_LINKS_SUCCESS',
    'GET_CAREERS_REQUEST',
    'GET_CAREERS_SUCCESS',
    'GET_JOB_REQUEST',
    'GET_JOB_SUCCESS',
    'SEARCH_REQUEST',
    'SEARCH_SUCCESS',
    'SUBSCRIBE_REQUEST',
    'SUBSCRIBE_SUCCESS',
    'SUBSCRIBE_FAILURE',
    'DISABLE_SPLASH',
    'FADE_SPLASH',
    'TOGGLE_MENU',
    'TOGGLE_SEARCH',
    'UPDATE_QUERY',
    'GET_BOILERPLATE_REQUEST',
    'GET_BOILERPLATE_SUCCESS',
    'SHOW_MODAL',
    'HIDE_MODAL',
    'GET_CURATORS_NOTEBOOK_REQUEST',
    'GET_CURATORS_NOTEBOOK_SUCCESS',
    'GET_CURATORS_NOTEBOOK_ITEM_REQUEST',
    'GET_CURATORS_NOTEBOOK_ITEM_SUCCESS',
    'HEADER_LINKS_REQUEST',
    'HEADER_LINKS_SUCCESS',
    'FOOTER_LINKS_REQUEST',
    'FOOTER_LINKS_SUCCESS',
    'FOOTER_CONTENT_REQUEST',
    'FOOTER_CONTENT_SUCCESS',
    'DONATION_REQUEST',
    'DONATION_SUCCESS',
    'SPECIAL_EVENT_REQUEST',
    'SPECIAL_EVENT_SUCCESS',
    'TOGGLE_HEADER_AND_FOOTER',
    'ALERT_MODAL_REQUEST',
    'ALERT_MODAL_SUCCESS',
)
