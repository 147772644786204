import React, { useState, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel as CarouselComp } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import {
    UpperHeader,
    Italic,
    Container,
    devices,
} from '../Common'
import { VideoModal } from '../Content'
import colors from '../../css/colors.scss'


export const Carousel = ({ slides }) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [carouselHeight, setCarouselHeight] = useState(800)
    const [showModal, setShowModal] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const carouselRef = useRef()

    const handleChange = (id) => setActiveSlide(id)

    const openModal = (video_url) => {
        setShowModal(true)
        setVideoUrl(video_url)
    }

    const closeModal = () => setShowModal(false)

    const setActive = (id) => () => handleChange(id)

    const updateCurrentSlide = (index) => {
        if (activeSlide !== index) setActiveSlide(index)
    }

    const updateHeight = () => {
        if (carouselRef.current.offsetWidth < 480) {
            setCarouselHeight(280)
        } else if (carouselRef.current.offsetWidth < 900) {
            setCarouselHeight(480)
        } else if (carouselRef.current.offsetWidth < 1240) {
            setCarouselHeight(647)
        } else if (carouselRef.current.offsetWidth < 2200) {
            setCarouselHeight(800)
        } else {
            setCarouselHeight(1100)
        }
    }

    useLayoutEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight)

        return () => {
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    return (
        <React.Fragment>
            <CarouselWrap ref={carouselRef}>
                {slides &&
                    <div>
                        <StyledCarousel
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            showIndicators={false}
                            infiniteLoop={true}
                            stopOnHover={false}
                            interval={10000}
                            transitionTime={1200}
                            autoPlay={true}
                            selectedItem={activeSlide}
                            onChange={updateCurrentSlide}
                        >
                            {slides.map(slide =>
                                slide.video_url ? (
                                    <VideoThumbnail
                                        key={slide.id.toString()}
                                        image={slide.video_thumbnail || slide.youtube_thumbnail || slide.vimeo_thumbnail}
                                        height={carouselHeight}
                                        role='img'
                                        aria-label={`${slide.title ? `${slide.title}: ` : ''}${slide.subtitle ? `${slide.subtitle}.  ` : ''}${slide.info}`}
                                    >
                                        <VideoPlayButton
                                            onClick={() => openModal(slide.video_url)}
                                            role='button'
                                            aria-label={`Play ${slide.title || slide.subtitle || slide.info} video`}
                                        />
                                    </VideoThumbnail>
                                ) : (
                                        <Slide
                                            background={slide.image}
                                            as={slide.linked_page ? Link : slide.external_url ? 'a' : ''}
                                            to={slide.linked_page ? slide.linked_page : ''}
                                            href={slide.external_url ? slide.external_url : ''}
                                            key={slide.id.toString()}
                                            height={carouselHeight}
                                            offset={100}
                                            role='img'
                                            aria-label={`${slide.title ? `${slide.title}: ` : ''}${slide.subtitle ? `${slide.subtitle}.  ` : ''}${slide.info}`}
                                        >
                                            <HeaderWrap>
                                                <UpperHeader>
                                                    {slide.subtitle}
                                                </UpperHeader>
                                                <MainHeader>
                                                    {slide.title}
                                                </MainHeader>
                                                <Italic fontSize={18}>
                                                    {slide.info}
                                                </Italic>
                                            </HeaderWrap>
                                            <DarkGradient show={slide.subtitle || slide.title || slide.info} />
                                        </Slide>
                                    )
                            )}
                        </StyledCarousel>
                    </div>
                }
            </CarouselWrap>
            {slides && slides.length > 1 && window.innerWidth > 450 ? (
                <Dots>
                    {slides.map((slide, i) =>
                        <Dot
                            key={i.toString()}
                            onClick={setActive(i)}
                            active={i === activeSlide}
                            role='button'
                            aria-label={`Hero Carousel Image ${i}`}
                        />
                    )}
                </Dots>
            ) : ''}
            <VideoModal
                url={videoUrl}
                showModal={showModal}
                closeModal={closeModal}
            />
        </React.Fragment>
    )
}

const CarouselWrap = styled.div`
    position: relative;
    width: 100%;
`

const DarkGradient = styled.div`
    height: 36%;
    ${props => props.show ? '' : 'display: none;'}
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;
    background: linear-gradient(
        0deg,
        ${colors.black} 0%,
        rgba(0, 0, 0, 0.5) 1%,
        rgba(0, 0, 0, 0) 100%
    );
    @media ${devices.mobile} {
        height: 50%;
    }
`

const MainHeader = styled.div`
    font-weight: 600;
    font-size: 44px;
    color: ${colors.white};
    line-height: 1.2;
    margin-bottom: 10px;

    @media ${devices.mobile} {
        font-size: 29px;
    }
`

const Slide = styled.div`
    display: flex;
    height: 647px;
    padding-top: 80px !important;
    text-align: left;
    position: relative;
    padding-bottom: 80px !important;
    background: center / cover no-repeat url(${props => props.background}) !important;
    text-decoration: none;
    @media ${devices.extraLarge} {
        height: 1100px;
    }
    @media ${devices.large} {
        height: 800px;
    }
    @media ${devices.tablet} {
        height: 480px;
    }
    @media ${devices.mobile} {
        height: 280px;
    }

    ${props => props.small && `
        height: 420px;
        @media ${devices.mobile} {
            height: 240px;
        }
        @media ${devices.tablet} {
            height: 360px;
        }
        @media ${devices.extraLarge} {
            height: 800px;
        }
        @media ${devices.large} {
            height: 420px;
        }
    `}

    @media ${devices.mobile} {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
`

const HeaderWrap = styled(Container)`
    z-index: 100;
    position: relative;
    align-self: flex-end;
    color: ${colors.white};
`

const StyledCarousel = styled(CarouselComp)`
    && {
        .control-dots {
            display: none;
        }
    }
`
const Dots = styled.div`
    margin-top: 20px;
    width: 100%;
    text-align: right;
    z-index: 2;
`

const Dot = styled.div`
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: ${colors.darkgray};
    margin-bottom: 30px;
    margin-right: 17px;
    cursor: pointer;
    opacity: ${({ active }) => active ? 1 : .5};
`

const VideoThumbnail = styled.div`
    height: ${props => props.height}px;
    width: 100%;
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
    cursor: pointer;
    // margin-bottom: 30px;

    @media ${devices.mobile} {
        height: 210px;
    }
`

const VideoPlayButton = styled.div`
    height: 100%;
    background-image: url(/static/images/Video_Play.png);
    background-position: center;
    background-size: 200px;
    background-repeat: no-repeat;
    cursor: pointer;

    @media ${devices.mobile} {
        height: 210px;
    }
`