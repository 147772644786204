import { survivorTypes } from '../_constants'

const defaultFilters = {
    'name': {
        active: true,
        value: ''
    },
    'birth_place': {
        active: false,
        value: ''
    },
    'concentration_camp': {
        active: false,
        value: ''
    },
    'immigration_year': {
        active: false,
        value: ''
    },
}

const initialState = {
    list: [],
    filterList: [],
    page: {},
    filtersActive: false,
    filters: defaultFilters,
    position: {
        x: 0,
        y: 0,
    }
}

export function survivors(state = initialState, action) {
    switch (action.type) {
        case survivorTypes.TOGGLE_FILTERS:
            return {
                ...state,
                filtersActive: !state.filtersActive
            }
        case survivorTypes.CHANGE_FILTER:
            const filters = {
                ...state.filters,
                ...action.payload
            }
            return {
                ...state,
                filters,
                filterList: filter_survivors(state.list, filters),
            }
        case survivorTypes.SURVIVOR_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                filterList: action.payload,
            }
        case survivorTypes.SURVIVOR_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload
            }
        case survivorTypes.CLEAR_SURVIVOR_FILTERS:
            return {
                ...state,
                filters: defaultFilters,
                filterList: state.list,
            }
        case survivorTypes.CLEAR_SURVIVOR:
            return {
                ...state,
                page: {}
            }
        case survivorTypes.UPDATE_POSITION:
            return {
                ...state,
                position: action.payload
            }
        case survivorTypes.FILTER_SURVIVORS:
            return {
                ...state,
                filterList: filter_survivors(state.list, action.payload.filters),
            }
        default:
            return state
    }
}

const filter_survivors = (survivors, filters) => {
    const newList = {}
    Object.entries(survivors).map(([k, v]) => {
        const newSurvivors = v.filter(e => {
            const nameFilter = filters.name.active ? e.name && e.name.toLowerCase().includes(filters.name.value.toLowerCase()) : true
            const birthPlaceFilter = filters.birth_place.active ? e.birth_place && e.birth_place.toLowerCase().includes(filters.birth_place.value.toLowerCase()) : true
            const concentrationCampFilter = filters.concentration_camp.active ? e.concentration_camp && e.concentration_camp.toLowerCase().includes(filters.concentration_camp.value.toLowerCase()) : true
            const yearFilter = filters.immigration_year.active ? e.immigration_year && e.immigration_year.includes(filters.immigration_year.value) : true
            return nameFilter && birthPlaceFilter && concentrationCampFilter && yearFilter
        })
        if (newSurvivors.length > 0) newList[k] = newSurvivors
    })
    return newList
}
