import React, { useEffect } from 'react'


export const TrackingScript = ({ scripts }) => {
    useEffect(() => {
        document.querySelectorAll('.tracking-code').forEach(e => e.parentNode.removeChild(e))
    }, [])

    useEffect(() => {
        const appendScript = e => {
            const script = document.createElement('script')
            script.innerHTML = e.replace(/<\/?script.*?>/g, '').replace(/<!--.*?-->/g, '').replace(/<noscript.*?>.*?<\/noscript>/g, '')
            document.head.appendChild(script)
            return script
        }

        const appendMeta = e => {
            const meta = document.createElement('meta')
            const attributes = e.match(/(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g)
            attributes.forEach(e => {
                const [k, v] = e.split('=')
                meta[k] = v
            })
            document.head.appendChild(meta)
            return meta
        }

        const appendedScripts = scripts.map(e => {
            if (e.match(/<\/?script.*?>/g)) return appendScript(e)
            else if (e.match(/<meta.*?\/>/g)) return appendMeta(e)
        })

        return () => appendedScripts.forEach(e => document.head.removeChild(e))
    }, [scripts])

    return <></>
}
